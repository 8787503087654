import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Services = ({ data }) => {
  const StyledServices = styled.div`
    .top-bg {
      height: 30rem;
      position: relative;
      h1 {
        position: absolute;
        bottom: -5rem;
        left: 50%;
        margin-bottom: 0;
        transform: translate(-50%, -50%);
        color: #ffffff;
        background: ${props => props.theme.colors.org};
        padding: 1.5rem 2.5rem;
        font-size: 2.5rem;
      }
      .gatsby-image-wrapper {
        height: 100%;
      }
    }
    .category-services {
      margin-bottom: 10rem;
      h2 {
        text-align: center;
        font-size: 2rem;
        margin-top: 10rem;
      }
      hr {
        width: 10%;
        margin: auto;
        border-color: ${props => props.theme.colors.org};
        margin-bottom: 5rem;
      }
      .services {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .service {
          width: 31.5%;
          margin-left: 2rem;
          margin-top: 5rem;
          &:hover {
            .details {
              background: ${props => props.theme.colors.org};
              border-color: ${props => props.theme.colors.org};
              h3,
              p,
              a {
                color: #ffffff;
              }
            }
          }
          .photo {
            height: 18rem;
            .gatsby-image-wrapper {
              height: 100%;
            }
          }
          .details {
            padding: 1rem 2rem;
            margin-top: 2rem;
            border: 1px solid#bbb;
            text-align: center;
            transition: 0.3s all ease-in-out;
            h3 {
              font-size: 1.8rem;
            }
            p {
              color: #828080;
              font-family: ${props => props.theme.fonts.sec};
              font-size: 1.4rem;
            }
            a {
              display: inline-block;
              margin-bottom: 2rem;
              color: ${props => props.theme.colors.org};
              text-decoration: none;
              letter-spacing: 0.2rem;
              font-size: 1.4rem;
            }
          }
        }
      }
    }
    @media (max-width: 1024px) {
      .category-services .services .service {
        margin-left: 1.6rem;
      }
    }
    @media (max-width: 768px) {
      .top-bg {
        height: 20vh;
      }
      .category-services .services {
        flex-wrap: nowrap;
        overflow-x: auto;
        justify-content: flex-start;
        .service {
          width: 55%;
          flex: 0 0 auto;
          margin-top: 2rem;
        }
      }
    }
    @media (max-width: 450px) {
      .category-services .services .service {
        width: 75%;
        .photo {
          height: 16rem;
        }
      }
    }
  `;

  return (
    <Layout>
      <SEO title="Services" />
      <StyledServices>
        <div className="top-bg">
          <Img fluid={data.file.childImageSharp.fluid} />
          <h1>SERVICES</h1>
        </div>
        <div className="category-services">
          <div className="container">
            <h2>Architectural Design</h2>
            <hr />
            <div className="services">
              {data.sectionOne.nodes.map((section, i) => (
                <motion.div
                  key={i}
                  initial={{ y: -80, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  className="service"
                >
                  <Link to={`/service/${section.slug}`}>
                    <div className="photo">
                      <Img
                        fluid={
                          section.acf.service_image.localFile.childImageSharp
                            .fluid
                        }
                      />
                    </div>
                  </Link>
                  <div className="details">
                    <h3>{section.acf.service_name}</h3>
                    <p>{section.acf.excerpt}</p>
                    <Link className="more" href={`/service/${section.slug}`}>
                      MORE
                    </Link>
                  </div>
                </motion.div>
              ))}
            </div>
            <h2>Engineering Design</h2>
            <hr />
            <div className="services">
              {data.sectionTwo.nodes.map((section, i) => (
                <motion.div
                  key={i}
                  initial={{ y: -80, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  className="service"
                >
                  <Link to={`/service/${section.slug}`}>
                    <div className="photo">
                      <Img
                        fluid={
                          section.acf.service_image.localFile.childImageSharp
                            .fluid
                        }
                      />
                    </div>
                  </Link>
                  <div className="details">
                    <h3>{section.acf.service_name}</h3>
                    <p>{section.acf.excerpt}</p>
                    <Link className="more" href={`/service/${section.slug}`}>
                      MORE
                    </Link>
                  </div>
                </motion.div>
              ))}
            </div>
            <h2>Construction Supervision</h2>
            <hr />
            <div className="services">
              {data.sectionThree.nodes.map((section, i) => (
                <motion.div
                  key={i}
                  initial={{ y: -80, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  className="service"
                >
                  <Link to={`/service/${section.slug}`}>
                    <div className="photo">
                      <Img
                        fluid={
                          section.acf.service_image.localFile.childImageSharp
                            .fluid
                        }
                      />
                    </div>
                  </Link>
                  <div className="details">
                    <h3>{section.acf.service_name}</h3>
                    <p>{section.acf.excerpt}</p>
                    <Link className="more" href={`/service/${section.slug}`}>
                      MORE
                    </Link>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </StyledServices>
    </Layout>
  );
};

export const data = graphql`
  {
    file(relativePath: { eq: "services.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    detailsBlockOne: file(relativePath: { eq: "details-pic-one.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    detailsBlockTwo: file(relativePath: { eq: "details-pic-two.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    detailsBlockThree: file(relativePath: { eq: "details-pic-three.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sectionOne: allWordpressWpServices(
      filter: { acf: { category: { eq: "Architectural Design" } } }
    ) {
      nodes {
        slug
        acf {
          service_name
          excerpt
          service_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    sectionTwo: allWordpressWpServices(
      filter: { acf: { category: { eq: "Engineering Design" } } }
    ) {
      nodes {
        slug
        acf {
          service_name
          excerpt
          service_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    sectionThree: allWordpressWpServices(
      filter: { acf: { category: { eq: "Construction Supervision" } } }
    ) {
      nodes {
        slug
        acf {
          service_name
          excerpt
          service_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Services;
